<template>
  <div>
    <el-dialog
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <el-button @click="cancelForm">取 消</el-button>
      <el-button type="primary" @click="addDataInfo()">保 存</el-button>
      <el-table
        :data="dataInfoList"
        border
        stripe
        style="width: 100%"
        align="center"
      >
        <el-table-column
          label="序号"
          :index="indexMethod"
          fixed
          type="index"
        ></el-table-column>
        <el-table-column label="期望日期" prop="expectedTime" />

        <el-table-column label="标题" prop="title" />
        <el-table-column label="状态" prop="status" width="150">
          <template slot-scope="scope">
            <template>
              <el-select
                v-model="scope.row.status"
                filterable
                placeholder="请选择"
                style="width: 70%"
              >
                <el-option
                  v-for="item in infoData"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="进度" prop="progressRate" width="120">
          <template slot-scope="scope">
            <el-slider v-model="scope.row.progressRate" show-stops> </el-slider>
          </template>
        </el-table-column>
        <el-table-column label="内容" prop="content" />
        <el-table-column label="等级" prop="grade" />
        <el-table-column label="预算" prop="budget" />
        <el-table-column label="排序" prop="sequence" />
        <el-table-column label="备注" prop="remark" />
      </el-table>
    </el-dialog>
  </div>
</template>
  <script>
import {
  selectplanByIdApi,
  editBondStructureApi,
} from "@/api/work/plan/plan.js";
export default {
  data() {
    return {
      dialog: false,
      editShow: false,
      title: "",
      ids: null,
      types: null,
      dataInfoList: [],
      uniqueId:null,
      infoData: [
        {
          key: 1,
          value: "正常",
        },
      ],
    };
  },
  methods: {
    show(row, type) {
      this.uniqueId=row.uniqueId
      this.dialog = true;
      this.ids = row.id;
      this.types = type;
      this.selectByIdButton(row.uniqueId);
    },
    handleClose(done) {
      this.$confirm("关闭")
        .then((_) => {
          this.cancelForm();
        })
        .catch((_) => {});
    },
    cancelForm() {
      this.dialog = false;
      this.editShow = false;
      this.dataInfoList = [];
      //调用父级查询
      this.$parent.listplanButton();
      this.$parent.id = null;
      this.$parent.questionnaireId = null;
    },
    indexMethod(index) {
      return index + 1;
    },
    addDataInfo() {
      var data={
        uniqueId:this.uniqueId,
        xunRestListVOList:this.dataInfoList,
      }
      editBondStructureApi(data).then((res) => {
        this.cancelForm();
      });
    },
    //查询详情
    selectByIdButton(id) {
      selectplanByIdApi(id).then((res) => {
        this.dataInfoList = res.result.xunPlanInfoReqVOS;
      });
    },
  },
};
</script>
  <style scoped>
</style>
  